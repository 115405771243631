import {SearchResultSetOfQueryableEntity, IFacetFieldInfo, FacetMemberInfo} from "types/searchTypes";
import AthenaClient, {
    IAthenaFacet,
    ISearchResultSetOfQueryablePerson,
    ISearchResultSetOfQueryableProject,
    ISearchResultSetOfQueryableCompany,
    ISearchResultSetOfQueryableContact
} from '../apiClients/AthenaClient';
//import { getFacetFieldList } from './facetMetadata';
import peopleFacets from 'constants/metadata/peopleFacets.json';
import projectsFacets from 'constants/metadata/projectsFacets.json';
import _ from "lodash";
import CoreDebug from "../../features/core/CoreDebug";
import {containsAny, getUserRoles, hasPermission} from "../../app/security/utils/securityUtil";
import currentUser from "../../recoil/currentUser";
import globalState from "../../app/state/GlobalState";

export type AthenaSearchResultSet = {
    metadata: IFacetFieldInfo[];
    data: SearchResultSetOfQueryableEntity;
}

function safeLowerCase(input: string | number | boolean | null | undefined): string {
    // guard clause for undefined and null
    if (input === undefined || input === null) {
        return '';
    }
    switch (typeof input) {
        case 'string':
            return input.toLowerCase();
        case 'number':
            return input.toString();
        case 'boolean':
            return input.toString();
        default:
            throw new Error('Not a valid type');
    }
}

function memberSortTest(fieldName: string, a: FacetMemberInfo, b: FacetMemberInfo) {
    const safeA = safeLowerCase(a.value);
    const safeB = safeLowerCase(b.value);
    try {
        return safeA.toLowerCase() > safeB.toLowerCase() ? 1 : -1;
    } catch (err) {
        throw new Error(`${safeA} is a ${typeof safeA} and this happened in ${fieldName}`)
    }
}

export default class FacetService {
    private athenaClient: AthenaClient;
    private log: (...args: any[]) => void;

    constructor(athenaClient: AthenaClient) {
        this.athenaClient = athenaClient;
        this.log = CoreDebug.getConsoleLogFunc();
    }

    async getPeopleFacets(): Promise<IFacetFieldInfo[]> {
        let facetFieldList = peopleFacets.map(x => x as IFacetFieldInfo)
            .filter(x => x.active === true);
        facetFieldList = facetFieldList.map(x => x);
        // facetFieldList = facetFieldList.filter(x => _.includes(['Companies'], x.label));
        //     'Experience', 'Hire Date'],x.label));
             // .filter(x => _.includes(['Total Project Value','Job Titles','Education'], x.label));

        const results = await this.athenaClient.getAllPeople(
            undefined, undefined, undefined, 0, undefined) as SearchResultSetOfQueryableEntity;

        for (const facetField of facetFieldList) {
            const fieldName = typeof facetField.field === 'string' ? facetField.field : facetField.field[0];
            // get the facets values/counts for the field from results.facets
            const facetMembers = results.facets ? results.facets[fieldName] : [] as FacetMemberInfo[];
            facetField.members = facetMembers.sort((a, b) => {
                return memberSortTest(fieldName, a, b);
            });
        }

        const statusFacet = facetFieldList.find((x: IFacetFieldInfo) => x.field === 'status');
        if (statusFacet) {
            this.log("statusFacet", statusFacet);
        }
        return facetFieldList;
    }

    async getProjectFacets(): Promise<IFacetFieldInfo[]> {
        const roles = globalState.userRoles;

        let facetFieldList = projectsFacets.map(x => x as IFacetFieldInfo)
            .filter(x => x.active === true);
        facetFieldList = facetFieldList.filter(x => hasPermission(x.authorization, roles))

        facetFieldList = facetFieldList.map(x => x);
        // facetFieldList = facetFieldList.filter(x => _.includes(['Companies'], x.label));
                 // .filter(x => _.includes(['Accredidations',
                 //  'Experience', 'Hire Date'],x.label));
            //.filter(x => x.type === 'category');
            //.filter(x => _.includes(['Vertical Markets','Sector','Delivery Method','Size FT²'], x.label));
        // todo: date picker and date range facet testing
            //.filter(x => _.includes(['Vertical Markets','Sector','Start Date'], x.label));
        //console.log('@@@@@ athenaClient test', {athenaClient: this.athenaClient});

        const results = (await this.athenaClient.getAllProjectsV2(
            undefined, undefined, undefined, 0, undefined) as SearchResultSetOfQueryableEntity);

        if (results.facets?.constructionType) {
            const constructionTypeCounts: { [key: string]: number } = {};

            results.facets.constructionType.forEach(facetMember => {
                const values = facetMember.value?.split(',').map(value => value.trim());
                values?.forEach(value => {
                    if (constructionTypeCounts[value]) {
                        constructionTypeCounts[value] += facetMember.count ?? 0;
                    } else {
                        constructionTypeCounts[value] = facetMember.count ?? 0;
                    }
                });
            });

            results.facets.constructionType = Object.keys(constructionTypeCounts).map(key => ({
                value: key,
                count: constructionTypeCounts[key],
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                init: () => {},
                toJSON: () => ({ value: key, count: constructionTypeCounts[key] })
            }));
        }
        
        for (const facetField of facetFieldList) {
            const fieldName = typeof facetField.field === 'string' ? facetField.field : facetField.field[0];
            // get the facets values/counts for the field from results.facets
            const facetMembers = results.facets ? results.facets[fieldName] : [] as FacetMemberInfo[];
            this.log('facetFieldName', fieldName);
            facetField.members = facetMembers.sort((a, b) => {
                return memberSortTest(fieldName, a, b);
            });
        }

        return facetFieldList;
    }
}